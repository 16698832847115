import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Careers = React.lazy(() => import("./components/pages/Careers"));
const Responsibility = React.lazy(() => import("./components/pages/Responsibility"));
const Investments = React.lazy(() => import("./components/pages/Investments"));



function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/careers" component={Careers} />
          <Route path="/responsibility" component={Responsibility} />
          <Route path="/investments" component={Investments} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
